/* eslint-disable */
import { FieldProps, getIn } from 'formik';
import * as React from 'react';

import { Box, SxProps, Typography } from '@mui/material';
import { FormControlProps } from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input, { InputProps } from '@mui/material/Input';

export interface SimpleFileUploadProps extends FieldProps {
    children: React.ReactNode;
    disabled?: boolean;
    InputProps?: Omit<InputProps, 'name' | 'type' | 'label'>;
    FormControlProps?: FormControlProps;
    uploaderSX?: SxProps;
    fileTypes?: string;
}

const defaultStyle: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#EBECF0',
    position: 'relative',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    borderRadius: '4px',
};

const uploaderStyle = Object.freeze({
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
});

const SimpleFileUpload = ({
    field,
    form: { isSubmitting, touched, errors, setFieldValue },
    children,
    InputProps,
    uploaderSX,
    disabled,
    fileTypes,
}: SimpleFileUploadProps) => {
    const error = getIn(touched, field.name) && getIn(errors, field.name);

    const handleFileChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const file = event.currentTarget.files?.[0];
            if (file) {
                setFieldValue(field.name, file);
                InputProps?.onChange?.(event);
            }
        },
        [field.name, setFieldValue, InputProps],
    );
    return (
        <Box
            component="label"
            sx={{
                ...defaultStyle,
                ...uploaderSX,
            }}
        >
            {children}
            <Input
                error={!!error}
                type="file"
                disabled={disabled || isSubmitting}
                name={field.name}
                onChange={handleFileChange}
                inputProps={{
                    style: uploaderStyle,
                    accept: fileTypes,
                }}
            />
            {error && (
                <FormHelperText sx={{ marginTop: 1 }} error>
                    {error}
                </FormHelperText>
            )}
        </Box>
    );
};

SimpleFileUpload.deafultProps = {
    uploaderSX: {},
    disabled: false,
    fileTypes: '*/*',
};

export default SimpleFileUpload;
