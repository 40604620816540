import { useCallback } from 'react';

import { useCurrencies } from 'src/currencies/hook';
import type { ProjectBriefFormData } from 'src/projectBrief/types';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { ProjectAnnouncementData } from 'src/types';
import { NavigatePayload } from 'src/types/navigation';

import {
    contactInformationUserSelector,
    projectChecklistLoadingSelector,
    projectChecklistSelector,
    projectCoverLetterSelector,
    projectDetailsLoadingSelector,
    projectDetailsStatusSelector,
    projectInfoSelector,
    projectInfoStatusSelector,
    projectLoadingSelector,
    projectProposalsSelector,
    projectSelector,
    projectStatusSelector,
    projectStepSelector,
    projectTimelineLoadingSelector,
    projectTimelineSelector,
    projectVendorsLoadingSelector,
    projectVendorsStatusSelector,
    selectCompanyInformation,
    selectFullProject,
    selectFullProjectInfo,
    selectProjectCoverLetterStatus,
    selectProjectDetails,
    selectProjectDetailsStepStatus,
    selectProjectInfoStepStatus,
    selectProjectVendorsStepStatus,
} from './selectors';
import {
    serializeProjectDetails,
    serializeProjectExtraVendor,
    serializeProjectInfo,
    serializeProjectTimeline,
    serializeProjectVendorContact,
    serializerProjectAnnouncement,
    serializerProjectChecklistItem,
} from './serializers';
import {
    ArchiveProjectFetcher,
    BulkDeleteChecklistItemsFetcher,
    GenerateChecklistItemsFetcher,
    ProjectAnnouncementsFetcherCreate,
    ProjectChecklistFetcherCreate,
    ProjectChecklistFetcherDelete,
    ProjectChecklistFetcherPatch,
    ProjectExtraVendorFetcher,
    RestoreProjectFetcher,
    creatProjectAndpatchProjectInfoAsync,
    createProjectAsync,
    createProjectVendorContactAsync,
    patchProjectDetailsAsync,
    patchProjectInfoAsync,
    patchProjectVendorContactAsync,
    updateProjectVendorContactAsync,
    updateProjectVendorsAsync,
    validateAndAutocompleteProjectVendorContactAsync,
} from './thunk';

export const useProjectTimelineLoading = () => useAppSelector(projectTimelineLoadingSelector);
export const useProjectDetailsLoading = () => useAppSelector(projectDetailsLoadingSelector);
export const useProjectVendorsLoading = () => useAppSelector(projectVendorsLoadingSelector);
export const useProjectLoading = () => useAppSelector(projectLoadingSelector);

export const useProjectDetailsStepStatus = () => useAppSelector(selectProjectDetailsStepStatus);
export const useProjectVendorsStepStatus = () => useAppSelector(selectProjectVendorsStepStatus);

export const useProjectInfoStepStatus = () => useAppSelector(selectProjectInfoStepStatus);

export const useProjectCoverLetterStatus = () => useAppSelector(selectProjectCoverLetterStatus);

export const useProjectCoverLetter = () => useAppSelector(projectCoverLetterSelector);

export const useProjectStep = () => useAppSelector(projectStepSelector);

export const useProject = () => useAppSelector(projectSelector).data;

export const useProjectProposals = () => useAppSelector(projectProposalsSelector);

export const useProjectVendorsSelector = () => useAppSelector(projectSelector).projectVendors.data;

export const useFullProject = () => useAppSelector(selectFullProject);

export const useProjectDetails = () => useAppSelector(selectProjectDetails);

export const useProjectTimeline = () => useAppSelector(projectTimelineSelector).data;

export const useProjectInfo = () => useAppSelector(selectFullProjectInfo);

export const useProjectInfoData = () => useAppSelector(projectInfoSelector).data;

export const useProjectCompanyInformation = () => useAppSelector(selectCompanyInformation);

export const useProjectInfoStatus = () => useAppSelector(projectInfoStatusSelector);

export const useProjectDetailsStatus = () => useAppSelector(projectDetailsStatusSelector);

export const useProjectChecklist = () => useAppSelector(projectChecklistSelector);

export const useProjectChecklistLoading = () => useAppSelector(projectChecklistLoadingSelector);

export const useProjectVendorsStatus = () => useAppSelector(projectVendorsStatusSelector);

export const useCreateProjectisLoading = () => {
    const status = useAppSelector(projectStatusSelector);
    return status === 'loading';
};

export const useCreateProject = () => {
    const dispatch = useAppDispatch();

    const currencies = useCurrencies();

    const euroCurrency = currencies.find((item) => item.isoCurrencyCode === 'EUR');
    return useCallback(
        (values) => {
            const payload = {
                currency: euroCurrency?.rawID,
                question_rounds: 2,
                project_name: values.projectTitle,
                fixed_fee: false,
                time_material: true,
            };
            dispatch(createProjectAsync(payload));
        },
        [dispatch, euroCurrency],
    );
};

export const useCreateProjectAndPatchProjectInfoAsync = () => {
    const dispatch = useAppDispatch();
    const currencies = useCurrencies();
    const projectInfoStatus = useProjectInfoStatus();

    return useCallback(
        (values: ProjectBriefFormData) => {
            const euroCurrency = (currencies || []).find((item) => item.isoCurrencyCode === 'EUR');
            const projectPayload = {
                currency: euroCurrency.rawID,
                question_rounds: 2,
                project_name: values.projectTitle,
                fixed_fee: false,
                time_material: true,
            };
            const projectTimelinePayload = serializeProjectTimeline(values);

            const projectInfoPayload = serializeProjectInfo(values);
            const payload = {
                projectInfoPayload,
                projectTimelinePayload,
                projectPayload,
            };
            if (projectInfoStatus !== 'loading') {
                dispatch(creatProjectAndpatchProjectInfoAsync(payload));
            }
        },
        [dispatch, projectInfoStatus, currencies],
    );
};

export const usePatchProjectInfoAsync = () => {
    const dispatch = useAppDispatch();
    const project = useProject();
    const projectInfoStatus = useProjectInfoStatus();

    return useCallback(
        async (values, navigatePayload: NavigatePayload = null) => {
            if (projectInfoStatus !== 'loading') {
                const projectInfoPayload = serializeProjectInfo(values);
                const projectTimelinePayload = serializeProjectTimeline(values);

                const payload = {
                    projectInfoPayload: { ...projectInfoPayload, id: project.id },
                    projectPayload: { project_name: values.projectTitle, id: project.id },
                    projectTimelinePayload: { ...projectTimelinePayload, id: project.id },
                    navigatePayload,
                };
                await dispatch(patchProjectInfoAsync(payload));
            }
        },
        [dispatch, projectInfoStatus, project?.id],
    );
};

export const usePatchProjectDetailsAsync = () => {
    const dispatch = useAppDispatch();
    const project = useProject();
    const projectDetailsStatus = useProjectDetailsStatus();

    return useCallback(
        async (values, navigatePayload: NavigatePayload = null) => {
            if (projectDetailsStatus !== 'loading') {
                const projectDetailsPayload = serializeProjectDetails(values);
                const projectTimelinePayload = serializeProjectTimeline(values);
                const payload = {
                    projectDetailsPayload: { ...projectDetailsPayload, id: project.id },
                    projectTimelinePayload: { ...projectTimelinePayload, id: project.id },
                    projectId: project.id,
                    navigatePayload,
                };
                return dispatch(patchProjectDetailsAsync(payload));
            }
            return new Promise(() => null);
        },
        [dispatch, projectDetailsStatus, project?.id],
    );
};

export const useUpdateProjectVendorsAsync = () => {
    const dispatch = useAppDispatch();
    const project = useProject();
    const projectVendorsStatus = useProjectVendorsStatus();

    return useCallback(
        (values) => {
            if (projectVendorsStatus !== 'loading') {
                const payload = {
                    companies: values,
                    id: project.id,
                };
                dispatch(updateProjectVendorsAsync(payload));
            }
        },
        [dispatch, projectVendorsStatus, project?.id],
    );
};

export const useCreateProjectExtraVendorAsync = () => {
    const dispatch = useAppDispatch();
    const project = useProject();
    const projectVendorsStatus = useProjectVendorsStatus();

    return useCallback(
        (values) => {
            if (projectVendorsStatus !== 'loading') {
                const payload = {
                    projectId: project.id,
                    data: serializeProjectExtraVendor(values),
                };
                dispatch(ProjectExtraVendorFetcher.action(payload));
            }
        },
        [dispatch, projectVendorsStatus, project?.id],
    );
};

export const useCreateProjectVendorContactAsync = () => {
    const dispatch = useAppDispatch();
    const project = useProject();
    const projectVendorsStatus = useProjectVendorsStatus();

    return useCallback(
        (values) => {
            if (projectVendorsStatus !== 'loading') {
                const payload = {
                    projectId: project.id,
                    data: serializeProjectVendorContact(values.data),
                    vendors: values.vendors,
                };
                dispatch(createProjectVendorContactAsync(payload));
            }
        },
        [dispatch, projectVendorsStatus, project?.id],
    );
};

export const useUpdateProjectVendorContactAsync = () => {
    const dispatch = useAppDispatch();
    const project = useProject();
    const projectVendorsStatus = useProjectVendorsStatus();

    return useCallback(
        (values) => {
            if (projectVendorsStatus !== 'loading') {
                const payload = {
                    projectId: project.id,
                    contactId: values.contactId,
                    data: serializeProjectVendorContact(values.data),
                    vendors: values.vendors,
                };
                dispatch(updateProjectVendorContactAsync(payload));
            }
        },
        [dispatch, projectVendorsStatus, project?.id],
    );
};

export const usePatchProjectVendorContactAsync = () => {
    const dispatch = useAppDispatch();
    const project = useProject();
    const projectVendorsStatus = useProjectVendorsStatus();

    return useCallback(
        (values) => {
            if (projectVendorsStatus !== 'loading') {
                const payload = {
                    projectId: project.id,
                    contactId: values.contactId,
                    data: serializeProjectVendorContact(values.data),
                    vendors: values.vendors,
                };
                dispatch(patchProjectVendorContactAsync(payload));
            }
        },
        [dispatch, projectVendorsStatus, project?.id],
    );
};

export const useContactInformationUserIndividual = () =>
    useAppSelector(contactInformationUserSelector);

export const useValidateAndAutocompleteProjectVendorContact = () => {
    const dispatch = useAppDispatch();
    const project = useProject();

    return useCallback(
        (email) => {
            const payload = {
                projectId: project.id,
                data: { email },
            };
            dispatch(validateAndAutocompleteProjectVendorContactAsync(payload));
        },
        [dispatch, project?.id],
    );
};

export const useCreateProjectCheckListItem = () => {
    const dispatch = useAppDispatch();
    const project = useProject();
    return useCallback(() => {
        const payload = {
            projectID: project.id,
            dataPayload: serializerProjectChecklistItem({
                title: '',
                text: '',
                done: false,
                aiGenerated: false,
            }),
        };

        dispatch(ProjectChecklistFetcherCreate.action(payload));
    }, [dispatch, project?.id]);
};

export const useCreateProjectAnnouncement = () => {
    const dispatch = useAppDispatch();
    const project = useProject();
    return useCallback(
        (data: ProjectAnnouncementData) => {
            const payload = {
                projectID: project.id,
                dataPayload: serializerProjectAnnouncement(data),
            };

            return dispatch(ProjectAnnouncementsFetcherCreate.action(payload));
        },
        [dispatch, project?.id],
    );
};

export const usePatchProjectCheckListItem = () => {
    const dispatch = useAppDispatch();
    const project = useProject();
    return useCallback(
        (updatedItem: any, index: number) => {
            const payload = {
                projectID: project.id,
                itemID: updatedItem.id,
                dataPayload: serializerProjectChecklistItem(updatedItem),
                index,
            };

            dispatch(ProjectChecklistFetcherPatch.action(payload));
        },
        [dispatch, project?.id],
    );
};

export const useDeleteProjectCheckListItem = () => {
    const dispatch = useAppDispatch();
    const project = useProject();
    return useCallback(
        (itemID: number, index: number) => {
            const payload = {
                projectID: project.id,
                itemID,
                index,
            };

            dispatch(ProjectChecklistFetcherDelete.action(payload));
        },
        [dispatch, project?.id],
    );
};

export const useGenerateChecklistItems = () => {
    const dispatch = useAppDispatch();
    const project = useProject();
    return useCallback(() => {
        dispatch({
            type: 'projectChecklist/setGenerateLoading',
            payload: { loading: true },
        });
        const payload = {
            projectID: project.id,
        };

        dispatch(GenerateChecklistItemsFetcher.action(payload));
    }, [dispatch, project?.id]);
};

export const useBulkDeleteChecklistItems = () => {
    const dispatch = useAppDispatch();
    const project = useProject();
    return useCallback(
        (itemIDs: Array<number>) => {
            const payload = {
                projectID: project.id,
                itemIDs,
            };

            dispatch(BulkDeleteChecklistItemsFetcher.action(payload));
        },
        [dispatch, project?.id],
    );
};

export const useArchiveProject = () => {
    const dispatch = useAppDispatch();
    return useCallback(
        (projectID: string) => {
            const payload = {
                projectID,
            };

            return dispatch(ArchiveProjectFetcher.action(payload));
        },
        [dispatch],
    );
};

export const useRestoreProject = () => {
    const dispatch = useAppDispatch();
    return useCallback(
        (projectID: string) => {
            const payload = {
                projectID,
            };

            return dispatch(RestoreProjectFetcher.action(payload));
        },
        [dispatch],
    );
};
