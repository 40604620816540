import { Field, useFormikContext } from 'formik';
import React from 'react';

import { Trans } from '@lingui/macro';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Checkbox, CircularProgress, Grid, Typography } from '@mui/material';

import SimpleFileUpload from 'src/components/formik-material-ui/SimpleFileUpload';
import { InternalSection } from 'src/components/sections/section';

import { useBriefFormData, useBriefStatus, useProjectBriefUploadDocuments } from './state/hooks';
import { ProjectBriefFormData } from './types';

// derived from https://platform.openai.com/docs/assistants/tools/file-search/supported-files#supported-files
const GPT_SUPPORTED_FILE_TYPES = '.doc,.docx,.pdf,.pptx,.md,.txt,.json';

const ProjectBriefDocument = () => {
    const { values, setFieldValue } = useFormikContext<ProjectBriefFormData>();
    const briefValues = useBriefFormData();
    const projectBriefUploadDocumentsFunction = useProjectBriefUploadDocuments();

    React.useEffect(() => {
        if (briefValues.projectBriefFile) {
            setFieldValue(
                'projectBriefFile',
                { name: values.projectBriefFile?.name || '', ...briefValues.projectBriefFile },
                false,
            );
        }
    }, [briefValues.projectBriefFile]);

    const isLoading = useBriefStatus() === 'loading';
    const handleFileChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const file = event.currentTarget.files?.[0];
            projectBriefUploadDocumentsFunction({
                file,
            });
        },
        [projectBriefUploadDocumentsFunction],
    );

    const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const isChecked = value === 'on';
        setFieldValue('withFileAttachment', isChecked, false);
    };

    return (
        <InternalSection title={<Trans>File Upload</Trans>}>
            <Grid container>
                <Grid item xs={12}>
                    <Box>
                        <Field name="projectBriefFile">
                            {({ field, form }: any) => (
                                <SimpleFileUpload
                                    field={field}
                                    InputProps={{ onChange: handleFileChange }}
                                    meta={null}
                                    fileTypes={GPT_SUPPORTED_FILE_TYPES}
                                    form={form}
                                    uploaderSX={{
                                        height: '100px',
                                    }}
                                >
                                    {!isLoading ? (
                                        <>
                                            {field.value && (
                                                <Typography
                                                    variant="body2"
                                                    sx={{ marginBottom: 1 }}
                                                >
                                                    {field.value.name}
                                                </Typography>
                                            )}
                                            <CloudUploadIcon
                                                sx={{ '&:hover': { color: 'primary.main' } }}
                                                fontSize="large"
                                                color="action"
                                            />
                                        </>
                                    ) : (
                                        <CircularProgress />
                                    )}
                                </SimpleFileUpload>
                            )}
                        </Field>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" textAlign="left">
                        <Field
                            component={(fieldProps) => (
                                <Checkbox
                                    {...fieldProps}
                                    color="primary"
                                    disabled={isLoading}
                                    size="medium"
                                    onChange={handleCheckBoxChange}
                                    checked={values.withFileAttachment}
                                    data-testid="withFileAttachmentCheckBox"
                                />
                            )}
                            name="withFileAttachment"
                        />
                        <Trans>
                            Check this box, if you also want to use this file as an attachment for
                            the vendors
                        </Trans>
                    </Typography>
                </Grid>
            </Grid>
        </InternalSection>
    );
};
export default ProjectBriefDocument;
