import { ReactNodeLike } from 'prop-types';
import { FC, Suspense } from 'react';

import { QuoteProvider } from 'src/components/rfp/QuoteProvider';
import DisabledSection from 'src/components/sections/disabledSection';
import useHasUserQuoteRoleReadOnly from 'src/hooks/useHasUserQuoteRoleReadOnly';

interface Props {
    children: ReactNodeLike;
}

const QuoteRoute: FC<Props> = ({ children }: Props) => {
    const hasReadOnly = useHasUserQuoteRoleReadOnly();

    return (
        <Suspense fallback="">
            <DisabledSection disabled={hasReadOnly}>
                <QuoteProvider>{children}</QuoteProvider>
            </DisabledSection>
        </Suspense>
    );
};

export default QuoteRoute;
