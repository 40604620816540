export interface MemberResponse {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
}

export interface Member {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
}

export interface ProposalDocumentResponse {
    id: number;
    file: string;
    file_path: string;
    type: number;
    extra_type: string;
}

export interface ProposalDocument {
    id: number;
    file: string;
    filePath: string;
    type: number;
    extraType: string;
}

export interface ProposalReferenceResponse {
    id: number;
    client_name: string;
    project_name: string;
    achievements: string;
}

export interface ProposalReference {
    id: number;
    clientName: string;
    projectName: string;
    achievements: string;
}

export interface ProposalTeamMemberResponse {
    id: number;
    project_role: string;
    name: string;
    specialization: string;
}

export interface ProposalTeamMember {
    id: number;
    projectRole: string;
    name: string;
    specialization: string;
}

export interface ProposalWorkPackageResponse {
    id: number;
    name: string;
    description: string;
    deliverables: string;
}

export interface ProposalWorkPackage {
    id: number;
    name: string;
    description: string;
    deliverables: string;
}

export interface ProposalTimeMaterialResponse {
    id: number;
    organization_worker_role: {
        id: string;
    };
    role: string;
    description: string;
    home_location: string;
    rate: number;
    days: number;
    analysis_days: number;
    concept_days: number;
    implementation_days: number;
    audit_days: number;
    order: number;
}

export interface ProposalTimeMaterial {
    id: number;
    organizationWorkerRole: {
        id: string;
    };
    role: string;
    description: string;
    homeLocation: string;
    rate: number;
    days: number;
    analysisDays: number;
    conceptDays: number;
    implementationDays: number;
    auditDays: number;
    order: number;
}

export interface ProposalFixedFeeResponse {
    id: number;
    project_phase: {
        id: string;
    };
    fee: number;
    number_of_units: number;
}

export interface ProposalFixedFee {
    id: number;
    projectPhase: {
        id: string;
    };
    fee: number;
    numberOfUnits: number;
}

export interface QuoteProposalResponse {
    id: number;
    status: string;
    is_active: boolean;
    participation_confirmed: boolean;
    accepted_at: string;
    concluded_at: string;
    duration_in_weeks: number;
    documents: ProposalDocumentResponse[];
    references: ProposalReferenceResponse[];
    team_members: ProposalTeamMemberResponse[];
    work_packages: ProposalWorkPackageResponse[];
    time_materials: ProposalTimeMaterialResponse[];
    fixed_fees: ProposalFixedFeeResponse[];
    financial_quotation_total: number;
    financial_quotation_data: {
        average_daily_rate_value: number;
        evaluation_final_score: number;
        financial_quotation_fee: number;
        intermediate_score: number;
        supplements_fee_value: number;
        supplements_value: number;
        total_project_volume_score: number;
        total_project_volume_value: number;
    };
    company: {
        company_logo: string;
        company_id: number;
        company_name: string;
    };
    contact_information: {
        contact_person_email: string;
        contact_person_phone: string;
        contact_person_job_title: string;
        contact_person_name: string;
        contact_person_salutation: number;
        contact_person_address_line_one: string;
        contact_person_address_line_two: string;
        contact_person_address_line_three: string;
        contact_person_postal_code: string;
        contact_person_city: string;
    };
    cancellation: {
        object_id: number;
        content_type: string;
        cancel_reason: string;
        cancel_cause: string;
    };
}

export interface QuoteProposal {
    id: number;
    status: string;
    isActive: boolean;
    participationConfirmed: boolean;
    acceptedAt: string;
    durationInWeeks: number;
    documents: ProposalDocument[];
    references: ProposalReference[];
    teamMembers: ProposalTeamMember[];
    workPackages: ProposalWorkPackage[];
    timeMaterials: ProposalTimeMaterial[];
    fixedFees: ProposalFixedFee[];
    concludedAt: string;
    financialQuotationTotal: number;
    financialQuotationData: {
        averageDailyRateValue: number;
        evaluationFinalScore: number;
        financialQuotationFee: number;
        intermediateScore: number;
        supplementsFeeValue: number;
        supplementsValue: number;
        totalProjectVolumeScore: number;
        totalProjectVolumeValue: number;
    };
    company: {
        companyLogo: string;
        companyId: number;
        companyName: string;
    };
    contactInformation: {
        contactPersonEmail: string;
        contactPersonPhone: string;
        contactPersonJobTitle: string;
        contactPersonName: string;
        contactPersonSalutation: number;
        contactPersonAddressLineOne: string;
        contactPersonAddressLineTwo: string;
        contactpersonAddressLineThree: string;
        contactPersonPostalCode: string;
        contactPersonCity: string;
    };
    cancellation: {
        objectId: number;
        contentType: string;
        cancelReason: string;
        cancelCause: string;
    };
}

export interface AnnouncementFileResponse {
    id: string;
    announcement: string;
    file: string;
    file_path: string;
}

export interface AnnouncementFile {
    id: string;
    announcement: string;
    file: string;
    filePath: string;
}

export interface UserLiteResponse {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
}

export interface UserLite {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
}

export interface ProjectAnnouncementResponse {
    id: number;
    announcement_set: string;
    user: UserLiteResponse;
    content: string;
    created_at: string;
    announcement_files: AnnouncementFileResponse[];
    proposals: Array<{
        id: string;
        company_name: string;
    }>;
    notification_sent: boolean;
}

export interface ProjectAnnouncement {
    id: number;
    announcementSet: string;
    user: UserLite;
    content: string;
    createdAt: string;
    announcementFiles: AnnouncementFile[];
    proposals: Array<{
        id: string;
        companyName: string;
    }>;
    notificationSent: boolean;
}

export interface AnnouncementFilePayload {
    announcement: string;
    id: string;
}

export interface ProjectAnnouncementPayload {
    content: string;
    announcement_files: AnnouncementFilePayload[];
    proposals: number[];
}

export interface ProjectAnnouncementData {
    content: string;
    announcementFiles: AnnouncementFilePayload[];
    proposals: number[];
}

export interface AnnouncementProposal {
    id: string;
    companyName: string;
}

// must be kept in sync with src/app/listmanager/types.py
export enum ListTypeEnum {
    CHECKLIST = 'checklist',
    VENDORS_LIST = 'vendorslist',
}

export enum UserCompanyRole {
    None = 'none',
    User = 'user',
    Admin = 'admin',
    ReadOnly = 'readonly',
}

export enum UserQuoteRole {
    None = 'none',
    User = 'user',
    Admin = 'admin',
    ReadOnly = 'readonly',
}

export enum RatecardProjectPermissions {
    None = 'none',
    User = 'user',
    Admin = 'admin',
    ReadOnly = 'readonly',
}
