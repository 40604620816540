import React from 'react';

import { Box } from '@mui/material';

interface DisabledSectionProps {
    disabled?: boolean;
    children: React.ReactNode;
}

const DisabledSection = ({ children, disabled }: DisabledSectionProps) => (
    <Box
        inert={disabled ? '' : undefined}
        sx={{
            opacity: disabled ? 0.5 : 1,
            pointerEvents: disabled ? 'none' : 'auto',
        }}
    >
        {children}
    </Box>
);

export default DisabledSection;
