import React from 'react';

import { useRatecardProposalQuery } from 'src/components/ratecardproposals/RatecardProposalProvider';
import DisabledSection from 'src/components/sections/disabledSection';

interface RatecardProposalReadOnlyGuardProps {
    children: React.ReactNode;
}

const RatecardProposalReadOnlyGuard = ({ children }: RatecardProposalReadOnlyGuardProps) => {
    const { ratecardProposalQuery } = useRatecardProposalQuery();
    const { isCurrentUserReadonly } = ratecardProposalQuery?.ratecardProposal;
    return <DisabledSection disabled={isCurrentUserReadonly}>{children}</DisabledSection>;
};

export default RatecardProposalReadOnlyGuard;
