import React from 'react';

import { useRatecardProjectQuery } from 'src/components/ratecards/RatecardProjectProvider';
import DisabledSection from 'src/components/sections/disabledSection';

interface RatecardReadOnlyGuardProps {
    children: React.ReactNode;
}

const RatecardReadOnlyGuard = ({ children }: RatecardReadOnlyGuardProps) => {
    const { ratecardProjectQuery } = useRatecardProjectQuery();
    const { isCurrentUserReadonly } = ratecardProjectQuery?.ratecardProject;

    return <DisabledSection disabled={isCurrentUserReadonly}>{children}</DisabledSection>;
};

export default RatecardReadOnlyGuard;
