import type { ProjectBriefFormData } from 'src/projectBrief/types';
import { GlobalState } from 'src/redux/store';

export const projectBriefSelector = (state: GlobalState) => state.projectBrief;

export const suggestionsStatusSelector = (state: GlobalState) =>
    projectBriefSelector(state).suggestionsLoadingStatus;

export const objectivesStatusSelector = (state: GlobalState) =>
    projectBriefSelector(state).objectivesLoadingStatus;

export const suggestionsSelector = (state: GlobalState) => projectBriefSelector(state).suggestions;

export const briefFormDataSelector = ({ projectBrief }): ProjectBriefFormData => projectBrief;

export const briefLoadingStatusSelector = (state: GlobalState) =>
    projectBriefSelector(state).loading;

export const isBriefLoadingSelector = (state: GlobalState) =>
    briefLoadingStatusSelector(state) === 'loading';

export const isLoadingSuggestionsSelector = (state) =>
    suggestionsStatusSelector(state) === 'loading';

export const isLoadingObjectivesSelector = (state) => objectivesStatusSelector(state) === 'loading';

export const briefDataSelector = (state: GlobalState) => projectBriefSelector(state).data;
