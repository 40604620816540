import type { CreateProjectPayload, NewChecklistItem, ProjectInfoPayload } from 'src/project/types';
import type { ProjectBriefFormData } from 'src/projectBrief/types';
import { ProjectAnnouncementData, ProjectAnnouncementPayload } from 'src/types';
import { dictionarySnakeCaseConverter } from 'src/utils/dictionarySnakeCaseCoverter';

import {
    Contact,
    ProjectChecklistItem,
    ProjectDetailsPayload,
    ProjectExtraVendor,
    ProjectRequestedVendor,
    ProjectTimelinePayload,
    ProjectVendor,
    ProjectVendorsFormData,
} from '../types';

export const serializeProjectPayload = (values, currency): CreateProjectPayload => ({
    currency: currency.rawID,
    question_rounds: 2,
    project_name: values.projectTitle,
    fixed_fee: false,
    time_material: true,
});

export const serializerProjectChecklistItem = (values: ProjectChecklistItem): NewChecklistItem => ({
    title: values.title,
    text: values.text,
    done: values.done,
    ai_generated: values.aiGenerated,
});

export const serializerProjectAnnouncement = (
    values: ProjectAnnouncementData,
): ProjectAnnouncementPayload => ({
    content: values.content,
    announcement_files: values.announcementFiles,
    proposals: values.proposals,
});

export const serializeProjectInfo = (values: ProjectBriefFormData) => {
    const quoteProjectGoals = values.projectObjectives
        ?.filter((item) => item.title && item.description)
        .map((item) => ({
            description: item.description,
            name: item.title,
        }));
    const projectDeliverables = values.projectDeliverables
        ?.filter((item) => item.title && item.description)
        .map((item) => ({
            description: item.description,
            name: item.title,
        }));

    const projectInfoPayload: ProjectInfoPayload = {
        internal_description: values.internalDescription,
        main_location: values.primaryProjectLocation,
        industry_standard: values.industryStandardID
            ? parseInt(atob(values.industryStandardID).split(':')[1], 10)
            : null,
        service_standard: values.selectedServiceID
            ? parseInt(atob(values.selectedServiceID).split(':')[1], 10)
            : null,
        quote_project_goals: quoteProjectGoals,
        project_deliverables: projectDeliverables,
        initial_situation: values.initialSituation,
        organization_project_reference: values.internalOrganisationReference,
        start_date: values.startDate,
        end_date: values.endDate,
        remote_consulting: values.engagementMode,
        secondary_locations: values.secondaryProjectLocations,
        project_approach: values.projectApproach,
        budget_estimate: values.budgetEstimate,
        budget_estimate_currency: values.budgetEstimateCurrency,
        budget_approval_status: values.budgetApprovalStatus,
    };
    if (values.withFileAttachment) {
        projectInfoPayload.project_document_id = values.projectBriefFile.id;
    }

    if (!projectInfoPayload.remote_consulting) {
        delete projectInfoPayload.remote_consulting;
    }
    return projectInfoPayload;
};

export const serializeProjectDetails = (values: any): ProjectDetailsPayload => {
    const payload = {} as any;

    if (values.currency) payload.currency = values.currency;
    if (values.projectPhases) {
        payload.project_phases = values.projectPhases
            .filter((projectPhase) => projectPhase.selected)
            .map((projectPhase) => projectPhase.id);
    }
    if (values.companyCountry) payload.company_country = values.companyCountry;
    if (values.mainLanguage) payload.main_language = values.mainLanguage;
    if (values.proposalLanguage) payload.proposal_language = values.proposalLanguage;
    if (values.secondaryLanguages) {
        payload.secondary_languages = values.secondaryLanguages;
    }
    if (values.timeMaterial) {
        payload.time_material = true;
        payload.fixed_fee = false;
    } else if (values.timeMaterial === false) {
        payload.time_material = false;
        payload.fixed_fee = true;
    }

    if (values.companySizeEmployees) payload.company_size_employees = values.companySizeEmployees;
    if (values.companySizeTurnover) payload.company_size_turnover = values.companySizeTurnover;
    const snakedValues = dictionarySnakeCaseConverter([values]);

    return {
        ...snakedValues[0],
        ...payload,
    };
};

export const serializeProjectTimeline = (values: any): ProjectTimelinePayload => {
    const payload = {} as any;

    if (values.endDate) payload.end_date = values.endDate;
    if (values.startDate) payload.start_date = values.startDate;
    if (values.presentationEndDate) payload.presentation_end_date = values.presentationEndDate;
    if (values.presentationStartDate)
        payload.presentation_start_date = values.presentationStartDate;
    if (values.projectAwardDate) payload.project_award_date = values.projectAwardDate;
    if (values.proposalDueDate) payload.proposal_due_date = values.proposalDueDate;
    if (values.rfpReleaseDueDate) payload.rfp_release_due_date = values.rfpReleaseDueDate;
    if (values.timelineEntries) payload.timeline_entries = values.timelineEntries;

    return payload;
};

export const serializeProjectExtraVendor = (values: ProjectExtraVendor) => ({
    company_name: values.companyName,
    website: values.website,
});

export const serializeProjectVendorContact = (values: Contact) => ({
    id: values.id,
    quote: values.quote,
    company: values.company,
    individual: {
        id: values.individual.id,
        first_name: values.individual.firstName,
        email: values.individual.email,
        phone: values.individual.phone,
        surname: values.individual.surname,
        userSpecific: true,
    },
    autoinvite: values.autoinvite,
});

export const serializeVendors = (values: ProjectVendor[]) =>
    values.map((value) => ({
        id: value.id,
        company_name: value.companyName,
        logo: value.logo,
        website: value.website,
        contact: value.contact ? serializeProjectVendorContact(value.contact) : null,
    }));

export const serializerProjectRequestedVendor = (values: ProjectRequestedVendor) => ({
    ...values,
    contact_person_name: values.contactPersonName,
    phone_number: values.phoneNumber,
    provider_name: values.providerName,
    provider_website: values.providerWebsite,
});

export const serializeProjectVendors = (values: ProjectVendorsFormData) => ({
    id: values.id,
    companies: serializeVendors(values.companies),
});
