import useAuth from './useAuth';
import useHasUserReadOnlyAccess from './useHasUserReadOnlyAccess';

// TODO create an enum that matches the backend roles and point to the relevant file
export const USER_BRIEF_ROLE = 'brief';

const useIsBriefEnabled = () => {
    const { user } = useAuth();
    const isBriefEnabled = window.ENABLE_PROJECT_BRIEFING && user.isClient;

    const hasReadOnly = useHasUserReadOnlyAccess();

    if (hasReadOnly) {
        return false;
    }
    return isBriefEnabled;
};

export default useIsBriefEnabled;
