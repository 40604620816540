import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import CloseIcon from '@mui/icons-material/Close';
import { Chip } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledRoleChip = styled(Chip)(({ theme }) => ({
    color: theme.palette.secondary.contrastText,
    fontWeight: 700,
    borderRadius: '4px',
    overflow: 'ellipsis',
    '&.admin': {
        backgroundColor: theme.palette.primary.main,
    },
    '&.user': {
        backgroundColor: '#3886ba',
    },
    '&.companyAdmin': {
        backgroundColor: theme.palette.success.main,
    },
    '&.readonly': {
        backgroundColor: '#4b5d67',
    },
}));

const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
    fontSize: 'medium',
    marginTop: theme.spacing(1),
}));

const RoleRenderer: React.FunctionComponent<ICellRendererParams> = ({
    value,
}: ICellRendererParams) => {
    const { i18n } = useLingui();
    value = value === 'company_admin' ? 'companyAdmin' : value;

    function getHeaderName(v: any) {
        if (v === 'companyAdmin') {
            return i18n._(t`Company Admin`);
        }
        if (v === 'admin') {
            return i18n._(t`Admin`);
        }
        if (v === 'user') {
            return i18n._(t`User`);
        }
        if (v === 'readonly') {
            return i18n._(t`Read Only`);
        }
        if (v === 'disabled') {
            return i18n._(t`Disabled`);
        }
        console.log('Missing translation for role', v);
        return '';
    }

    return (
        <div>
            {value !== null ? (
                <StyledRoleChip
                    label={getHeaderName(value)}
                    size="small"
                    key={value}
                    title={getHeaderName(value)}
                    className={value}
                />
            ) : (
                <StyledCloseIcon />
            )}
        </div>
    );
};

export default RoleRenderer;
