import { useLingui } from '@lingui/react';
import AddIcon from '@mui/icons-material/Add';
import { Button, Grid } from '@mui/material';

import CardSection from 'src/components/sections/cardSection';
import useAuth from 'src/hooks/useAuth';
import useHasUserReadOnlyAccess from 'src/hooks/useHasUserReadOnlyAccess';
import { ApaduaRole } from 'src/types/user';

import List from './list';
import ListFormModal from './listFormModal';
import {
    useCreateList,
    useCreateOrganisationVendorList,
    useDeleteList,
    useOrganizationListsData,
    useUpdateList,
    useUserListsData,
} from './state/hooks';

const ListManagerPage = () => {
    const { i18n } = useLingui();
    const { user } = useAuth();
    const lists = useUserListsData();
    const organizationLists = useOrganizationListsData();
    const isOrganisationAdmin = user?.apaduaRole === ApaduaRole.ADMIN;
    const createList = useCreateList();
    const createOrganisationList = useCreateOrganisationVendorList();
    const deleteList = useDeleteList();
    const updateList = useUpdateList();
    const hasReadOnly = useHasUserReadOnlyAccess();

    const addList = (values) => {
        createList(values);
    };

    const addOrganizationList = (values) => {
        createOrganisationList(values);
    };

    const handleUpdateList = (updatedList) => {
        updateList(updatedList);
    };

    const handleDeleteList = (list) => {
        deleteList(list.id);
    };

    const triggerButtonComponent = ({ handleOpen }) => (
        <Button
            variant="contained"
            color="primary"
            disabled={hasReadOnly}
            endIcon={<AddIcon />}
            onClick={handleOpen}
        >
            {i18n._('Add List')}
        </Button>
    );

    return (
        <Grid container display="flex" flexDirection="column" p={2}>
            <CardSection
                title={i18n._('Organization lists')}
                tooltipText={i18n._(
                    'This list will be available to all users of your organization.',
                )}
                actions={
                    isOrganisationAdmin && (
                        <ListFormModal
                            onConfirm={addOrganizationList}
                            triggerButtonComponent={triggerButtonComponent}
                        />
                    )
                }
            >
                <>
                    {organizationLists.map((list) => (
                        <List
                            key={list.id}
                            list={list}
                            isOrganisation={!!true}
                            onUpdate={handleUpdateList}
                            onDelete={handleDeleteList}
                        />
                    ))}
                </>
            </CardSection>
            <CardSection
                title={i18n._('My lists')}
                actions={
                    <ListFormModal
                        onConfirm={addList}
                        triggerButtonComponent={triggerButtonComponent}
                    />
                }
            >
                <>
                    {lists.map((list) => (
                        <List
                            key={list.id}
                            list={list}
                            isOrganisation={false}
                            onUpdate={handleUpdateList}
                            onDelete={handleDeleteList}
                        />
                    ))}
                </>
            </CardSection>
        </Grid>
    );
};

export default ListManagerPage;
