import React from 'react';

import { useQuoteProposalQuery } from 'src/components/rfp/QuoteProposalProvider';
import DisabledSection from 'src/components/sections/disabledSection';

interface QuoteProposalReadOnlyGuardProps {
    children: React.ReactNode;
}

const QuoteProposalReadOnlyGuard = ({ children }: QuoteProposalReadOnlyGuardProps) => {
    const { quoteProposalQuery } = useQuoteProposalQuery();
    const { isCurrentUserReadonly } = quoteProposalQuery?.sourceProposal;

    return <DisabledSection disabled={isCurrentUserReadonly}>{children}</DisabledSection>;
};

export default QuoteProposalReadOnlyGuard;
