import { useProject } from 'src/project/state/hook';
import { UserQuoteRole } from 'src/types';

const useHasUserQuoteRoleReadOnly = () => {
    const project = useProject();
    const metadata = project?.metadata;

    return metadata?.user?.role === UserQuoteRole.ReadOnly;
};

export default useHasUserQuoteRoleReadOnly;
