import type {
    ChecklistItemResponse,
    ChecklistResponse,
    CompanyInformation,
    CompanyInformationResponse,
    Contact,
    Individual,
    ProjectChecklistItem,
    ProjectDetailsFormData,
    ProjectDetailsResponse,
    ProjectInfoResponse,
    ProjectMetadata,
    ProjectMetadataResponse,
    ProjectRequest,
    ProjectRequestedVendor,
    ProjectResponse,
    ProjectTimelineFormData,
    ProjectTimelineResponse,
    ProjectVendorsFormData,
    ProjectVendorsResponse,
    RawContact,
    RawIndividual,
    RawProjectRequestedVendor,
} from 'src/project/types';
import { ProjectBriefFormData } from 'src/projectBrief/types';
import {
    Member,
    ProjectAnnouncement,
    ProjectAnnouncementResponse,
    QuoteProposal,
    QuoteProposalResponse,
} from 'src/types';
import { dictionaryCamelCaseConverter } from 'src/utils/dictionaryCamelCaseConverter';

export interface ProjectFormData {
    id?: number;
    projectTitle: string;
    status: string;
    request?: ProjectRequest;
    companyInformation?: CompanyInformation;
    members?: Member[];
    projectCoverLetter?: any; // TODO we keep this until there is a separate slice
    proposals?: QuoteProposal[];
    isArchived?: boolean;
    metadata: ProjectMetadata;
}

export const sanitizeProject = (apiData: ProjectResponse): ProjectFormData => ({
    id: apiData.id,
    projectTitle: apiData.project_name,
    status: apiData.status,
    projectCoverLetter: {
        coverLetterTypeWasChosen: apiData.project_cover_letter?.cover_letter_type_was_chosen,
        coverLetterDocument: apiData.project_cover_letter?.cover_letter_document,
        uploadedCoverLetter: apiData.project_cover_letter?.uploaded_cover_letter,
        isComplete: apiData.project_cover_letter?.is_complete,
    },
    request: apiData.request
        ? {
              firstName: apiData.request.person_first_name,
              lastName: apiData.request.person_last_name,
              email: apiData.request.project_administrator_email,
              additionalInformation: apiData.request.additional_information,
              projectRequestedVendors: sanitizeServiceProvicers(
                  apiData.request.project_requested_vendors,
              ),
          }
        : null,
    members: dictionaryCamelCaseConverter(apiData.members),
    companyInformation: sanitizeCompanyInformation(apiData.company_information),
    proposals: sanitizeQuoteProposals(apiData.proposals),
    isArchived: apiData.is_archived,
    metadata: sanitizeMetadata(apiData.metadata),
});

export const sanitizeMetadata = (apiData: ProjectMetadataResponse) => apiData;

export const sanitizeChecklist = (apiData: ChecklistResponse) => ({
    id: apiData?.id,
    name: apiData?.name,
    checkListItems: (apiData?.list_items || []).map((item) => sanitizeChecklistItem(item)),
});

export const sanitizeChecklistItem = (apiData: ChecklistItemResponse): ProjectChecklistItem => ({
    id: apiData.id,
    title: apiData.title,
    text: apiData.text,
    done: apiData.done,
    aiGenerated: apiData.ai_generated,
});

export const sanitizeIndividual = (apiData: RawIndividual): Individual => ({
    id: apiData.id,
    firstName: apiData.first_name,
    email: apiData.email,
    phone: apiData.phone,
    surname: apiData.surname,
    userSpecific: apiData.user_specific,
});

export const sanitizeContact = (apiData: RawContact): Contact => ({
    id: apiData.id,
    quote: apiData.quote,
    company: apiData?.company,
    individual: apiData?.individual && sanitizeIndividual(apiData.individual),
    isVerified: apiData?.is_verified,
    autoinvite: apiData?.autoinvite,
});

export const sanitizeServiceProvicers = (
    apiData: RawProjectRequestedVendor[],
): ProjectRequestedVendor[] =>
    dictionaryCamelCaseConverter(apiData) as unknown as ProjectRequestedVendor[];

export const sanitizeProjectVendors = (
    apiData: ProjectVendorsResponse,
): ProjectVendorsFormData => ({
    id: apiData.id,
    isComplete: apiData.is_complete,
    companies: apiData.companies.map((company) => ({
        id: company.id,
        companyName: company.company_name,
        logo: company.logo,
        website: company.website,
        contact: company.contact ? sanitizeContact(company.contact) : null,
    })),
});

export const sanitizeProjectAnnouncements = (
    apiData: Array<ProjectAnnouncementResponse>,
): Array<ProjectAnnouncement> =>
    apiData?.map((announcement) => ({
        id: announcement.id,
        announcementSet: announcement.announcement_set,
        user: dictionaryCamelCaseConverter([announcement.user])[0],
        content: announcement.content,
        notificationSent: announcement.notification_sent,
        proposals: dictionaryCamelCaseConverter(announcement.proposals),
        createdAt: announcement.created_at,
        announcementFiles: dictionaryCamelCaseConverter(announcement?.announcement_files || []),
    }));
interface RawValidatedVendorContactInformation {
    company: any;
    user: any;
}

export const sanitizeValidatedVendorContactInformation = (
    apiData: RawValidatedVendorContactInformation | null,
) => {
    if (!apiData.user || !apiData.company) return null;

    return {
        existingContactUser: {
            firstName: apiData.user.first_name,
            surname: apiData.user.last_name,
            phone: apiData.user.mobile_phone || apiData.user.phone,
            email: apiData.user.email,
        },
        company: {
            companyName: apiData.company.company_name,
            companyNameAlias: apiData.company.company_name_alias,
            id: apiData.company.id,
        },
    };
};

export const sanitizeProjectDetails = (apiData: ProjectDetailsResponse): ProjectDetailsFormData => {
    const formData = {} as ProjectDetailsFormData;
    if (apiData.documents) {
        formData.documents = apiData.documents;
    }

    formData.useDefaultCompany = apiData.use_default_company;

    if (apiData.other_mentions) {
        formData.otherMentions = apiData.other_mentions;
    }
    if (apiData.approach_presentation) {
        formData.approachPresentation = apiData.approach_presentation;
    }
    if (apiData.currency) {
        formData.currency = apiData.currency;
    }
    if (apiData.proposal_validity_weeks) {
        formData.proposalValidityWeeks = apiData.proposal_validity_weeks;
    }
    if (apiData.pitch_session) {
        formData.pitchSession = apiData.pitch_session;
    }
    if (apiData.client_introduction) {
        formData.clientIntroduction = apiData.client_introduction;
    }
    if (apiData.company_name) {
        formData.companyName = apiData.company_name;
    }
    if (apiData.company_address_line_one) {
        formData.companyAddressLineOne = apiData.company_address_line_one;
    }
    if (apiData.company_address_line_two) {
        formData.companyAddressLineTwo = apiData.company_address_line_two;
    }
    if (apiData.company_address_line_three) {
        formData.companyAddressLineThree = apiData.company_address_line_three;
    }
    if (apiData.company_postal_code) {
        formData.companyPostalCode = apiData.company_postal_code;
    }
    if (apiData.company_city) {
        formData.companyCity = apiData.company_city;
    }
    if (apiData.company_website) {
        formData.companyWebsite = apiData.company_website;
    }
    if (apiData.company_country) {
        formData.companyCountry = apiData.company_country;
    }
    if (apiData.company_portrait) {
        formData.companyPortrait = apiData.company_portrait;
    }
    if (apiData.company_size_employees) {
        formData.companySizeEmployees = apiData.company_size_employees;
    }
    if (apiData.company_size_turnover) {
        formData.companySizeTurnover = apiData.company_size_turnover;
    }
    if (apiData.hybrid_model) {
        formData.hybridModel = apiData.hybrid_model;
    }
    if (apiData.main_location) {
        formData.mainLocation = apiData.main_location;
    }
    if (apiData.main_language) {
        formData.mainLanguage = apiData.main_language;
    }
    if (apiData.no_personal_contact_information) {
        formData.noPersonalContactInformation = apiData.no_personal_contact_information;
    }
    if (apiData.outline_project_phases) {
        formData.outlineProjectPhases = apiData.outline_project_phases;
    }
    if (apiData.project_phases) {
        formData.projectPhases = dictionaryCamelCaseConverter(apiData.project_phases || []);
    }
    if (apiData.project_references) {
        formData.projectReferences = apiData.project_references;
    }
    if (apiData.projects_worked_on) {
        formData.projectsWorkedOn = apiData.projects_worked_on;
    }
    if (apiData.proposal_language) {
        formData.proposalLanguage = apiData.proposal_language;
    }
    if (apiData.main_language) {
        formData.mainLanguage = apiData.main_language;
    }
    if (apiData.question_rounds) {
        formData.questionRounds = apiData.question_rounds;
    }
    if (apiData.quote_contacts) {
        formData.quoteContacts = dictionaryCamelCaseConverter(apiData.quote_contacts);
    }
    if (apiData.secondary_languages) {
        formData.secondaryLanguages = apiData.secondary_languages;
    }
    if (apiData.standard_profile) {
        formData.standardProfile = apiData.standard_profile;
    }
    if (apiData.success_based) {
        formData.successBased = apiData.success_based;
    }
    if (apiData.time_material) {
        formData.timeMaterial = true;
        formData.fixedFee = false;
    } else if (apiData.fixed_fee) {
        formData.timeMaterial = false;
        formData.fixedFee = true;
    }
    if (apiData.closed_envelope) {
        formData.closedEnvelope = apiData.closed_envelope;
    }

    formData.isComplete = apiData.is_complete;

    return formData;
};

export const sanitizeProjectTimeline = (
    apiData: ProjectTimelineResponse,
): ProjectTimelineFormData => {
    const formData = {} as ProjectTimelineFormData;

    if (apiData.start_date) {
        formData.startDate = apiData.start_date;
    }
    if (apiData.end_date) {
        formData.endDate = apiData.end_date;
    }
    if (apiData.presentation_end_date) {
        formData.presentationEndDate = apiData.presentation_end_date;
    }
    if (apiData.presentation_start_date) {
        formData.presentationStartDate = apiData.presentation_start_date;
    }
    if (apiData.project_award_date) {
        formData.projectAwardDate = apiData.project_award_date;
    }
    if (apiData.proposal_due_date) {
        formData.proposalDueDate = apiData.proposal_due_date;
    }
    if (apiData.rfp_release_due_date) {
        formData.rfpReleaseDueDate = apiData.rfp_release_due_date;
    }
    if (apiData.timeline_entries) {
        formData.timelineEntries = apiData.timeline_entries;
    }

    return formData;
};

export const sanitizeProjectInfo = (
    apiData: ProjectInfoResponse,
): Omit<ProjectBriefFormData, 'projectTitle'> => {
    const sanitizedData: Omit<ProjectBriefFormData, 'projectTitle'> = {
        startDate: apiData.start_date,
        endDate: apiData.end_date,
        internalDescription: apiData.internal_description || '',
        projectApproach: apiData.project_approach || '',
        initialSituation: apiData.initial_situation || '',
        internalOrganisationReference: apiData.organization_project_reference || '',
        engagementMode: apiData?.remote_consulting || '',
        projectObjectives: (
            (apiData.quote_project_goals?.length > 0 && apiData.quote_project_goals) || [
                { name: '', description: '' },
            ]
        ).map((item) => ({
            title: item.name,
            description: item.description,
        })),
        projectDeliverables: (
            (apiData.project_deliverables?.length > 0 && apiData.project_deliverables) || [
                { name: '', description: '' },
            ]
        ).map((item) => ({
            title: item.name,
            description: item.description,
        })),
        secondaryProjectLocations: apiData.secondary_locations,
        selectedServiceID:
            apiData.service_standard && btoa(`ServiceStandardNode:${apiData.service_standard}`),
        industryStandardID:
            apiData.industry_standard && btoa(`IndustryStandardNode:${apiData?.industry_standard}`),
        primaryProjectLocation: apiData.main_location,
        isComplete: apiData.is_complete,
        budgetEstimate: apiData.budget_estimate,
        budgetApprovalStatus: apiData.budget_approval_status,
        budgetEstimateCurrency: apiData.budget_estimate_currency,
    };

    return sanitizedData;
};

export const sanitizeCompanyInformation = (
    apiData: CompanyInformationResponse,
): CompanyInformation => ({
    name: apiData.company_name,
    addressLine1: apiData.address_line_1,
    addressLine2: apiData.address_line_2,
    addressLine3: apiData.address_line_3,
    postalCode: apiData.postal_code,
    city: apiData.city,
    country: apiData.country,
    employees: apiData.company_size_employees,
    turnover: apiData.company_size_turnover,
});

export const sanitizeQuoteProposals = (apiData: QuoteProposalResponse[]): QuoteProposal[] =>
    apiData?.map((quoteProposal) => ({
        id: quoteProposal.id,
        isActive: quoteProposal.is_active,
        participationConfirmed: quoteProposal.participation_confirmed,
        status: quoteProposal.status,
        acceptedAt: quoteProposal.accepted_at,
        concludedAt: quoteProposal.concluded_at,
        durationInWeeks: quoteProposal.duration_in_weeks,
        documents: dictionaryCamelCaseConverter(quoteProposal.documents || []),
        references: dictionaryCamelCaseConverter(quoteProposal.references || []),
        teamMembers: dictionaryCamelCaseConverter(quoteProposal.team_members || []),
        workPackages: dictionaryCamelCaseConverter(quoteProposal.work_packages || []),
        timeMaterials: dictionaryCamelCaseConverter(quoteProposal.time_materials || []),
        fixedFees: dictionaryCamelCaseConverter(quoteProposal.fixed_fees || []),
        financialQuotationTotal: quoteProposal.financial_quotation_total,
        financialQuotationData: {
            averageDailyRateValue:
                quoteProposal?.financial_quotation_data?.average_daily_rate_value,
            evaluationFinalScore: quoteProposal?.financial_quotation_data?.evaluation_final_score,
            financialQuotationFee: quoteProposal?.financial_quotation_data?.financial_quotation_fee,
            intermediateScore: quoteProposal?.financial_quotation_data?.intermediate_score,
            supplementsFeeValue: quoteProposal?.financial_quotation_data?.supplements_fee_value,
            supplementsValue: quoteProposal?.financial_quotation_data?.supplements_value,
            totalProjectVolumeScore:
                quoteProposal?.financial_quotation_data?.total_project_volume_score,
            totalProjectVolumeValue:
                quoteProposal?.financial_quotation_data?.total_project_volume_value,
        },
        company: {
            companyLogo: quoteProposal.company?.company_logo,
            companyId: quoteProposal.company?.company_id,
            companyName: quoteProposal.company?.company_name,
        },
        contactInformation: {
            contactPersonEmail: quoteProposal.contact_information?.contact_person_email,
            contactPersonPhone: quoteProposal.contact_information?.contact_person_phone,
            contactPersonJobTitle: quoteProposal.contact_information?.contact_person_job_title,
            contactPersonName: quoteProposal.contact_information?.contact_person_name,
            contactPersonSalutation: quoteProposal.contact_information?.contact_person_salutation,
            contactPersonAddressLineOne:
                quoteProposal.contact_information?.contact_person_address_line_one,
            contactPersonAddressLineTwo:
                quoteProposal.contact_information?.contact_person_address_line_two,
            contactpersonAddressLineThree:
                quoteProposal.contact_information?.contact_person_address_line_three,
            contactPersonPostalCode: quoteProposal.contact_information?.contact_person_postal_code,
            contactPersonCity: quoteProposal.contact_information?.contact_person_city,
        },
        cancellation: {
            objectId: quoteProposal.cancellation?.object_id,
            contentType: quoteProposal.cancellation?.content_type,
            cancelReason: quoteProposal.cancellation?.cancel_reason,
            cancelCause: quoteProposal.cancellation?.cancel_cause,
        },
    }));
